<template>
    <div class="autonym">

    </div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style lang="less" scoped>
.autonym {

}
</style>
